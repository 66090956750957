"use client";
import { useQuery } from "@tanstack/react-query";
import { getIndexCampaign } from "../../src/app/api/listingFunction";
import { SkeletonLoaderCardGroup } from "../../components/resuables/SkeletonLoader";
import ListingCardGroup from "../../components/listing/ListingCardGroup";
import styles from "../index/index.module.css";

interface campaignItem {
    campaign: string;
    description: string;
}

export default function ListingIndex() {
    const { isFetching, isError, data } = useQuery({
        queryKey: ["indexCampaign"],
        queryFn: () => getIndexCampaign(),
    });

    if (isFetching) return <SkeletonLoaderCardGroup />;
    if (isError) return null;

    return (
        <>
            {data.map((item: campaignItem) => (
                <section
                    key={item.campaign}
                    className={styles.indexlistingStack}
                >
                    <h3 className={styles.sectionHeading}>{item.campaign}</h3>
                    <h4 className={styles.sectiondescription}>
                        {item.description}
                    </h4>
                    <ListingCardGroup campaign={item.campaign} />
                </section>
            ))}
        </>
    );
}
